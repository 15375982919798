import React from 'react';
import { Breadcrumb, Col, Container, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import PageHeading from './PageHeading';
import articlesData from './Articles';
import blogsData from './Blogs';
import newsData from './News';

const ArticleAndBlogsDetail = () => {
    const { type, slug } = useParams();
    let dataSource;

    if (type === 'articles') {
        dataSource = articlesData;
    } else if (type === 'blogs') {
        dataSource = blogsData;
    } else if (type === 'news') {
        dataSource = newsData;
    }

    const item = dataSource.find((item) => item.slug === slug);

    if (!item) {
        return <div>Item not found</div>;
    }

    const headings = [
        'Introduction',
        'The Importance of Credentials Verification',
        'Impact on Patient Safety',
        'Continuous Professional Development',
        'Conclusion'
    ];

    return (
        <>
            <PageHeading heading={item.title} />
            <Col xs={12} lg={8} className='mx-auto py-lg-5 py-2' >
                <Breadcrumb className='ps-3'>
                    <Breadcrumb.Item className='text-decoration-none'>
                        <Link to={`/${type}`}>{type}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{item.slug}</Breadcrumb.Item>
                </Breadcrumb>

                <Container>
                    <Image src={item.image} width={"100%"} style={{ borderRadius: "30px" }} />

                    <div className='py-5'>
                        <h4>{headings[0]}</h4>
                        <p className='text-muted fs-5'>{item.content[0]}</p>

                        <h4>{headings[1]}</h4>
                        <p className='text-muted fs-5'>{item.content[1]}</p>

                        <h4>{headings[2]}</h4>
                        <p className='text-muted fs-5'>{item.content[2]}</p>

                        {item.content[3] && (
                            <>
                                <h3>{headings[3]}</h3>
                                <p className='text-muted fs-5'>{item.content[3]}</p>
                            </>
                        )}

                        <h4>{headings[4]}</h4>
                        <p className='text-muted fs-5'>{item.content[4]}</p>
                    </div>
                    <hr />

                    <div className="author-section py-3 px-4 mt-4" style={{
                        backgroundColor: '#f8f9fa',
                        borderRadius: '8px',
                        textAlign: 'center',
                        border: '1px solid #e9ecef',
                    }}>
                        <h5 className='text-muted mb-1' style={{ fontWeight: 'bold' }}>Author</h5>
                        <h5 className='mb-1' style={{ fontSize: '1.25rem', color: '#495057' }}>Alka</h5>
                        <h6 className='fst-italic text-muted' style={{ fontSize: '0.9rem' }}>{item.date}</h6>
                    </div>
                </Container>
            </Col>
        </>
    );
};

export default ArticleAndBlogsDetail;
