import articles from 'components/Shared/Articles';
import ArticlesAndBlogsCards from 'components/Shared/ArticlesAndBlogsCards';
import PageHeading from 'components/Shared/PageHeading';
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';

const Articles = () => {
    return (
        <>
         <PageHeading heading={"Articles"} />
            <Container className='py-5' data-aos="fade-up">
               
                <Row >
                    {articles.map((blog) => (
                        <Col xs={12} md={6} lg={4} key={blog.id}>
                            <ArticlesAndBlogsCards
                                slug={blog.slug}
                                title={blog.title}
                                content={blog.content}
                                image={blog.image}
                                type="articles"
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
};

export default Articles;
