import ArticlesAndBlogsCards from 'components/Shared/ArticlesAndBlogsCards';
import blogsData from 'components/Shared/Blogs';
import PageHeading from 'components/Shared/PageHeading';
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';

const Blogs = () => {
    return (
        <>
                  <PageHeading heading={"Blogs"} />
            <Container className=' py-5' data-aos="fade-up">
      
                <Row >
                    {blogsData.map((blog) => (
                        <Col xs={12} md={6} lg={4} key={blog.id}>
                            <ArticlesAndBlogsCards
                                slug={blog.slug}
                                title={blog.title}
                                content={blog.content}
                                image={blog.image}
                                type="blogs"
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
};

export default Blogs;
