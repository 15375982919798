import React, { useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { SubmitContactForm } from "services/ContactService";
import { toast } from "react-toastify";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [catagory, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const categoryOptions = [
    "Technical Issues",
    "Billing & Payments",
    "Product Inquiries",
    "Complaints & Feedback",
    "Account Management",
    "Policy Questions"
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        name,
        email,
        catagory,
        subject,
        message,
      };

      const response = await SubmitContactForm(payload);

      if (response.status === 201) {
        toast.success("Thankyou for submitting. We will get back to you as soon as possible.");
        setName("");
        setEmail("");
        setCategory("");
        setSubject("");
        setMessage("");
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card className="px-3 py-1 mb-3 rounded-3">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="my-2" controlId="formBasicName">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              name="user_name"
              className="bg-transparent py-2"
              placeholder="Enter your name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="my-2" controlId="formBasicEmail">
            <Form.Label className="required">Email</Form.Label>
            <Form.Control
              type="email"
              className="bg-transparent py-2"
              placeholder="Enter your email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="user_Email"
              required
            />
          </Form.Group>

          <Form.Group className="my-2" controlId="formBasicCategory">
            <Form.Label className="required">Category</Form.Label>
            <Form.Select
              aria-label="Select category"
              value={catagory}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Select a category</option>
              {categoryOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="my-2" controlId="formBasicSubject">
            <Form.Label className="required">Subject</Form.Label>
            <Form.Control
              type="text"
              className="bg-transparent py-2"
              placeholder="Enter subject..."
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              name="subject"
              required
            />
          </Form.Group>

          <Form.Group className="my-2" controlId="formBasicMessage">
            <Form.Label className="required">Message</Form.Label>
            <Form.Control
              className="bg-transparent"
              placeholder="Enter your message..."
              as="textarea"
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              name="message"
              required
            />
          </Form.Group>

          <div className="text-end my-3">
            <Button
              className="px-4 py-2 global-btn glowing-border transform-hover text-black bg-white"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Submitting...
                </>
              ) : (
                "SUBMIT"
              )}
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default ContactForm;
