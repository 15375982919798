import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'

const MissionAndVision = ({ services }) => {
    return (
        <>
            {services.map((step, index) => (
                <Row
                    key={step.id}
                    className={`align-items-center my-5 ${index % 2 === 1 ? '' : 'flex-row-reverse'}`}
                    data-aos="fade-up"
                >
                    <Col xs={12} lg={6} className='d-flex align-items-center'>
                        <div>

                            <h2 className='fw-semibold mt-2'>{step.title}</h2>
                            <p className='text-muted' style={{fontSize:"18px"}}>
                                {step.description}
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className='text-center'>
                        <Image src={step.img} width={'85%'} style={{ borderRadius: "30px" }} />
                    </Col>
                </Row>
            ))}
        </>
    )
}

export default MissionAndVision