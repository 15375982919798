export const menuItems = [
    { href: '/', label: 'Home' },
    { href: '/about', label: 'About' },
    { href: '/pricing', label: 'Pricing' },
    {
        label: 'Resources',
        href:"articles",
        dropdown: true, 
        subItems: [
            { href: '/articles', label: 'Articles' },
            { href: '/blogs', label: 'Blogs' },
            { href: '/news', label: 'News' }
        ]
    },
    { href: '/contact', label: 'Contact Us' },
    { href: '/faqs', label: 'FAQs' },
];
