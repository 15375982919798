
import Navigation from 'Navigation'
import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
const App = () => {

  useEffect(() => {
    AOS.init();
  }, []);
 
  return (
    <>
      <Navigation />

    </>
  )
}

export default App