import React from "react";
import { Card } from "react-bootstrap";
import { FiPhone } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { IoMailOpenOutline } from "react-icons/io5";

const ContactInformation = () => {
    const containerStyle = {
        width: "100%",
        height: "245px",
    };

    const center = {
        lat: 43.1083076,
        lng: -79.0622035,
    };

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDFh1g2GhFvl3b_T8G__6C7Qh0pNo_8PdI",
    });

    const handleMarkerClick = () => {
        window.open(
            `https://www.google.com/maps?q=${center.lat},${center.lng}`,
            '_blank'
        );
    };

    const contactData = [
        {
            icon: <FiPhone size={26} color="#04ECF0" />,
            title: "Contact on phone",
            content: <Link to="tel:289-296-4148" className="text-muted text-decoration-none">(289)-296-4148</Link>,
        },
        {
            icon: <IoMailOpenOutline size={26} color="#04ECF0" />,
            title: "Contact on mail",
            content: (
                <Link to="mailto:verihealth@gmail.com" className="text-muted text-decoration-none">
                    contactus@verihealth.ca
                </Link>
            ),
        },
        {
            icon: <HiOutlineLocationMarker size={26} color="#04ECF0" />,
            title: "Location",
            content: (
                <>
                    4551 Zimmerman Ave., Niagara Falls,<br />
                    Ontario, Canada L2E 3M5
                </>
            ),
        },
    ];
    

    

    if (!isLoaded) {
        return <div>Loading Map...</div>;
    }

    return (
        <>
            {contactData.map((contact, index) => (
                <Card key={index} className=" py-1 px-3 mb-3 rounded-3">
                    <div className="d-flex py-2 align-items-center">

                        <div className="contact-icons">
                            <span className="rotateicon">{contact.icon}</span>


                        </div>
                        <div className="mx-3 contact mt-2">
                            <h6 className="text-black fw-bold m-0">{contact.title}</h6>
                            <p className="m-0 text-muted" style={{fontSize:"18px"}}>{contact.content}</p>
                        </div>
                    </div>
                    {index === contactData.length - 1 && (
                        <div className="map-container" style={{ marginTop: "24px" }}>
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={15}
                            >
                                <MarkerF position={center} onClick={handleMarkerClick} />
                            </GoogleMap>
                        </div>
                    )}
                </Card>
            ))}
        </>
    );
};

export default ContactInformation;