import PageHeading from 'components/Shared/PageHeading';
import React from 'react';
import { Accordion, Card, Container } from 'react-bootstrap';

const FaqsComponent = ({ faqData }) => {
   

    return (
        <>
         <PageHeading heading={"Frequently Asked Questions"} />
            <Container className='py-5' data-aos="fade-up">
               
                <Card className=' border-0' style={{ borderRadius: "30px" }}>
                    <Accordion defaultActiveKey="0" flush>
                        {faqData.map((faq, index) => (
                            <Accordion.Item className='py-2' eventKey={index.toString()} key={index}>
                                <Accordion.Header>
                                    <span className='me-2'>{index + 1} -</span>
                                    {faq.question}
                                </Accordion.Header>
                                <Accordion.Body>{faq.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Card>
            </Container>
        </>
    );
};

export default FaqsComponent;
