import Contact from 'pages/Contact'
import About from 'pages/About'
import Faqs from 'pages/Faqs'
import Home from 'pages/Home'
import Packages from 'pages/Packages'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ArticleAndBlogsDetail from 'components/Shared/ArticleAndBlogsDetail'
import Articles from 'components/Resources/Articles'
import Blogs from 'components/Resources/Blogs'
import News from 'components/Resources/News'

const Navigation = () => {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/pricing" element={<Packages />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/news" element={<News />} />
        <Route path="/:type/:slug" element={<ArticleAndBlogsDetail />} />

      </Routes>
    </>
  )
}

export default Navigation