
import { Config } from 'Config';
import axios from 'axios';
const baseURL = Config.API_BASE_URL;

export const GetApiData = async (endpoint, method, payload, secured) => {
    let apiOptions = { url: baseURL + endpoint }
    if (method !== '') apiOptions.method = method
    if (payload != null) apiOptions.data = payload
    return await axios(apiOptions);
}

