import React, { useState } from 'react';
import { Offcanvas, Nav, Collapse, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Sidebar = ({ show, handleClose, navLinks }) => {
    const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpand = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(null); 
        } else {
            setExpandedIndex(index); 
        }
    };
    const handleSubItemClick = () => {
        setExpandedIndex(null); 
        handleClose(); 
    };

    return (
        <Offcanvas show={show} onHide={handleClose} className="bg-black" placement="end" style={{ width: "270px" }}>
            <Offcanvas.Header closeButton  >
                <Offcanvas.Title className='text-white'> Navigation</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-0'>
                <Nav className="flex-column">
                    {navLinks.map((link, index) => (
                        link.dropdown ? (
                            <div key={index} className="w-100">
                                <div
                                    className="d-flex justify-content-between align-items-center py-3 my-1 px-3 text-white"
                                    onClick={() => toggleExpand(index)}
                                    style={{ cursor: 'pointer', background: 'black'}}
                                >
                                    {link.label}
                                    {expandedIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                                </div>
                                <Collapse in={expandedIndex === index}>
                                    <div>
                                        {link.subItems.map((subItem, subIndex) => (
                                            <NavLink
                                                key={subIndex}
                                                to={subItem.href}
                                                className="text-decoration-none py-2 px-4 d-block"
                                                style={({ isActive }) => ({
                                                    background: isActive ? '#69f0ea' : 'black',
                                                    color: isActive ? '#000000' : '#ffffff',
                                                })}
                                                onClick={handleSubItemClick}
                                            >
                                                {subItem.label}
                                            </NavLink>
                                        ))}
                                    </div>
                                </Collapse>
                            </div>
                        ) : (
                            <NavLink
                                key={index}
                                to={link.href}
                                className="text-decoration-none py-3 my-1 px-3"
                                style={({ isActive }) => ({
                                    background: isActive ? '#69f0ea' : 'black',
                                    color: isActive ? '#000000' : '#ffffff',
                                })}
                                onClick={handleClose}
                            >
                                {link.label}
                            </NavLink>
                        )
                    ))}
                    <Button as={Link} to={redirectUrl} target='_black' className='mx-3 px-4 py-2 global-btn glowing-border transform-hover bg-transparent text-decoration-none'>Register / SignIn</Button>
                </Nav>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default Sidebar;
