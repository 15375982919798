import PageHeading from "components/Shared/PageHeading";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContactInformation from "../components/ContactComponents/ContactInformation";
import ContactForm from "../components/ContactComponents/ContactForm";


const Contact = () => {

    return (
        <>
        <PageHeading heading={"CONTACT US"} />
            <Container className="py-5" data-aos="fade-up">
                
                <Row className="pb-5">
                    <Col xs={12} lg={5}>
                        <ContactInformation />
                    </Col>
                    <Col xs={12} lg={7}>
                        <ContactForm />
                    </Col>
                </Row>

            </Container>
        </>
    );
};

export default Contact;
