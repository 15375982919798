import React from 'react'
import FaqsComponent from '../components/Faqs/Faqs';
import Banner from 'components/Shared/Banner';
const Faqs = () => {
    const faqData = [
        {
            question: "What is credential verification?",
            answer: "Credential verification is the process of confirming the validity and authenticity of a healthcare professional's educational background, certifications, licenses, and other qualifications. It ensures that healthcare staff meet the necessary requirements to practice in their field."
        },
        {
            question: "Why is credential verification important?",
            answer: "Credential verification is crucial for maintaining the highest standards of patient safety and quality care in the healthcare industry. It helps healthcare organizations and patients trust that professionals have the necessary qualifications and skills to provide competent and reliable services."
        },
        {
            question: "How long does the verification process take?",
            answer: "The verification process timelines may vary depending on the complexity and volume of credentials submitted. Our team strives to complete the verification process efficiently while maintaining accuracy and reliability."
        },
        {
            question: "Is my personal information secure?",
            answer: "At VeriHealth, we prioritize the security and confidentiality of your personal information. We use secure encryption and data protection measures to safeguard your data throughout the verification process."
        },
        {
            question: "Can I update my credentials on the platform?",
            answer: "Yes, you can update your credentials on the platform at any time. Simply log in to your account, upload the updated documents, and our team will review and verify them accordingly."
        },
        {
            question: "What happens if my credentials are not verified?",
            answer: "If your credentials are not verified for any reason, our team will provide detailed feedback and guidance on the next steps to address any discrepancies or issues. We are committed to assisting you in resolving any verification challenges."
        },
        {
            question: "How can I contact customer support?",
            answer: "For any questions, concerns, or technical assistance, please reach out to our customer support team through the contact information provided on our website. Our dedicated team is here to help you with any inquiries you may have."
        },
        {
            question: "What is the price for membership?",
            answer: "Please contact us to discuss membership pricing and options tailored to your specific needs."
        }
    ];

    return (
        <>
            <FaqsComponent faqData={faqData} />
            <Banner />
        </>
    )
}

export default Faqs