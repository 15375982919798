import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { TypeAnimation } from 'react-type-animation';
import heroVideo from '../../assets/hero.mp4';
import { Link } from 'react-router-dom';
import verified from "../../assets/v.png";

const Banner = () => {
    const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
    return (
        <Container>
            <div className='hero-section my-5' data-aos="fade-up" id='verified'>
                <video autoPlay muted loop playsInline style={{ width: "100%", height: "100%" }}>
                    <source src={heroVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className='overlay'></div>
                <Container className='position-relative text-white'>
                    <Row className='justify-content-center align-items-center py-5'>
                        <Col xs={12} lg={10} className='d-flex flex-column align-items-center text-center banner py-5'>
                            <h1 className='fw-bold'>
                                Get Yourself 
                                <span className='ms-2' style={{ color: "#04ECF0", display: 'inline-flex', alignItems: 'baseline' }}>
                                    <Image  width="40px" style={{marginRight:"-15px"}} src={verified} alt="V" />
                                    eriFied
                                </span> Today
                            </h1>

                            <TypeAnimation
                                sequence={[
                                    'Your Trusted Partner in Healthcare Credential Verification',
                                    500,
                                ]}
                                wrapper='span'
                                speed={50}
                                style={{ fontSize: '18px', display: 'inline-block' }}
                                repeat={Infinity}
                                className='typing-text'
                            />

                            <Button
                                as={Link}
                                to={redirectUrl}
                                target='_blank'
                                className='px-4 py-2 mt-3 global-btn glowing-border transform-hover bg-transparent'
                                style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}
                            >
                                Register Now
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Container>
    );
};

export default Banner;
