import React, { useState } from 'react';
import { Container, Row, Col, Image, Form, Button, Spinner } from 'react-bootstrap';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import logo from "../../assets/logo.png";
import { Link } from 'react-router-dom';
import { menuItems } from './MenuItems';
import { addDoc, collection } from 'firebase/firestore';
import { db } from 'services/Firebase';
import { toast } from 'react-toastify';
import { AiFillInstagram } from "react-icons/ai";
const Footer = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubscribe = async (e) => {
        e.preventDefault();
        if (!email) {
            const errorMessage = "Please enter a valid email address.";
            toast.error(errorMessage);
            return;
        }
        setLoading(true); 
        try {
            await addDoc(collection(db, "newsletterSubscribers"), {
                email,
                subscribedAt: new Date().toISOString()
            });
            const successMessage = "Subscribed successfully!";
            toast.success(successMessage);
            setEmail("");
        } catch (error) {
            const errorMessage = "Error subscribing to newsletter.";
            toast.error(errorMessage);
            console.error(errorMessage, error);
        }
        setLoading(false); 
    };

    const firstHalf = menuItems.slice(0, 4);
    const secondHalf = menuItems.slice(4);

    return (
        <footer className="bg-black text-white pt-5 pb-4">
            <Container>
                <Row className="justify-content-between align-items-start py-3">
                    <Col xs={12} md={4} className="mb-4 mb-md-0">
                        <div className="text-start">
                            <div className="text-center text-lg-start">
                                <Image src={logo} width={"130px"} alt="VeriHealth Logo" />
                            </div>

                            <p className="mt-3 fw-bold fst-italic">
                                Enhancing Care Through Trust and Transparency
                            </p>
                            <p className="mb-1"><strong>Email:</strong> contactus@verihealth.ca</p>
                            <p className="mb-1"><strong>Phone:</strong> (289)-296-4148</p>
                            <div className='d-flex gap-2'>
                                <p className="mb-1"><strong>Address:</strong></p>
                                <p className="mb-1">4551 Zimmerman Ave., Niagara Falls,<br /> Ontario, Canada L2E 3M5</p>
                            </div>

                        </div>
                    </Col>

                    <Col xs={12} md={4} className="text-center text-md-start mb-4 mb-md-0">
                        <Row>
                            <Col xs={6}>
                                <ul className="list-unstyled mt-lg-5 mt-2">
                                    {firstHalf.map((link, index) => (
                                        <li key={index} className='mb-2'>
                                            <Link to={link.href} style={{ color: "grey" }} className="text-decoration-none">
                                                {link.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                            <Col xs={6}>
                                <ul className="list-unstyled mt-lg-5 mt-2">
                                    {secondHalf.map((link, index) => (
                                        <li key={index} className='mb-2'>
                                            <Link to={link.href} style={{ color: "grey" }} className="text-decoration-none">
                                                {link.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} md={4}>
                        <div className="mb-3 text-lg-start text-center">
                            <h5>Follow Us</h5>
                            <div className=' d-flex gap-3 justify-content-lg-start justify-content-center'>
                                <Link target='_blank' to="https://www.facebook.com/p/verihealth-61559612950352/" className="text-white "><FaFacebookF /></Link>
                                <Link target='_blank' to="https://www.instagram.com/verihealthinc/" className="text-white"><AiFillInstagram /></Link>
                                <Link href="#" className="text-white"><FaLinkedinIn /></Link>
                            </div>
                        </div>
                        <div className='text-lg-start text-center'>
                            <h5 className="mb-3">Subscribe to our Newsletter</h5>
                            <Form  onSubmit={handleSubscribe}>
                                <Row className='align-items-center'>
                                    <Col xs={8}>
                                    <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    className="me-2"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{minHeight:"42px"}}
                                />
                                    </Col>
                                    <Col xs={4}>
                                    <Button
                                    variant="primary"
                                    className='px-4 py-2 global-btn glowing-border transform-hover text-white bg-black w-100'
                                    type="submit"
                                    disabled={loading} 
                                >
                                    {loading ? (
                                        <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                                    ) : (
                                        "Subscribe"
                                    )}
                                </Button>
                                    </Col>
                                </Row>
                               
                               
                            </Form>

                        </div>
                    </Col>
                </Row>
                <Row className="border-top pt-3 mt-4">
                    <Col xs={12} md={6}>
                        <p className="small mb-0 text-md-start text-center">©️ 2024 VeriHealth. All rights reserved.</p>
                    </Col>
                    <Col xs={12} md={6} className="text-md-end text-center">
                        <Link to="/privacy-policy" className="text-white text-decoration-none mx-2">Privacy Policy</Link>
                        <Link to="/terms-of-service" className="text-white text-decoration-none mx-2">Terms of Service</Link>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
