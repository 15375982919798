import Banner from 'components/Shared/Banner';
import PageHeading from 'components/Shared/PageHeading';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';

const Packages = () => {
    
    const packageData = [
        {
            title: 'Healthcare staff',
            features: [
                'Complete document submission portal',
                'Professional license verification',
                'Background check verification',
                'Real-time status tracking',
                'Email & Ticket based support',
            ],
            duration: [
                'Verification completed in 5-7 business days',
                'Expedited options available (extra fee)',
                '2 document revision opportunities',
            ],
            additional: [
                'Includes QR code for easy facility verification',
                'Access to personal verification dashboard',
            ],
        },
        {
            title: 'Agency',
            features: [
                'Company-specific portal access',
                'Unlimited Staff Verification Annually',
                'Employee profile management',
                'Email & Ticket based support',
            ],
            duration: [
                'Verification completed in 5-7 business days',
                'Expedited options available (extra fee)',
                '2 document revision opportunities',
            ],
            additional: [
                'Includes QR code for easy facility verification',
                'Access to personal verification dashboard',
            ],
        },
        {
            title: 'Facility',
            features: [
                'Company-specific portal access',
                'Unlimited Staff Verification Annually',
                'Employee profile management',
                'Email & Ticket based support',
                'Access to Agency’s staff files',
            ],
            duration: [
                'New employee verifications within 5-7 business days',
                'Company portal setup in 3 business days',
                '2 support tickets per month',
            ],
            additional: [
                'QR code generation for facility scanning',
                'Compliance tracking for employee records',
            ],
        },
        
    ];


    return (
        <>
        <PageHeading heading={"Pricing"} />
        <div className='linesbg mt-4'>
            <Container className='py-5' data-aos="fade-up">
                
                <Row className="outerhow justify-content-center">
                    {packageData.map((pricing, index) => (
                        <Col xs={12} md={6} lg={4} key={index} className="my-2">
                            <div className={`pricing_table shadow ${pricing.active ? 'active' : ''}`}>
                                <div className="pricing_table_header text-black d-flex justify-content-center gap-2 align-items-center">
                                    <h4 className="title">{pricing.title}</h4>
                                    {pricing.employeeSize && (
                                        <h5 className='text-muted'>
                                            ({`employee size ${pricing.icon} ${pricing.employeeSize}`})
                                        </h5>
                                    )}
                                </div>
                                <div className="pricing_table_plan d-flex flex-column bg-white" style={{ minHeight: "400px" }}>
                                    <ul className='text-start' style={{minHeight:"250px"}}>
                                        {pricing.features.map((feature, featureIndex) => (
                                            <li className="text-black d-flex gap-2" key={featureIndex}>
                                                <div className='d-flex align-items-center justify-content-center' style={{ height: "20px", width: "20px", background: "green", borderRadius: "60px" }}>
                                                    <TiTick  size={18} className='text-white bg-transparent' />
                                                </div>  {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <hr style={{ color: "#67ece6" }} />
                                  
                                   

                                    <div className="mt-auto">

                                        <Button as={Link} to={"https://calendly.com/"} target='_blank'  className='px-4 py-2 mt-3 global-btn glowing-border transform-hover bg-transparent text-black' >BOOK A DEMO</Button>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    ))}
                </Row>
           
            </Container>
            </div>

            <Banner />
        </>
    );
};

export default Packages;
