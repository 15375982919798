import PageHeading from 'components/Shared/PageHeading';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { LiaUserFriendsSolid } from "react-icons/lia";
import { MdAutoGraph } from "react-icons/md";
import { MdOutlinePrivacyTip } from "react-icons/md";

const reasons = [
    {
        id: 1,
        title: 'Industry Expertise',
        description: 'Benefit from the knowledge and experience of our team of experts in healthcare credential verification.',
        icon: <MdAutoGraph size={50}  color="#04ECF0"  />,
    },
    {
        id: 2,
        title: 'Secure and Reliable',
        description: 'Our platform uses advanced encryption and data protection measures to ensure your information is always secure.',
        icon: <MdOutlinePrivacyTip size={50}  color="#04ECF0"  />,
    },
    {
        id: 3,
        title: 'User-Friendly Experience',
        description: 'Enjoy a seamless and intuitive experience with our user-friendly platform.',
        icon: <LiaUserFriendsSolid size={50}  color="#04ECF0"  />,
    },
];

const WhyChooseUs = () => {
    return (
        <>
            <div className='pentagonbg' id='why' style={{ backgroundColor: "#f7f4f4" }}>
                <Container className='py-lg-5 py-3' data-aos="fade-up">
                    <PageHeading heading={'Why VeriHealth'}  sub/>
                    <Col xs={12} lg={8} className='text-center mx-auto'>
                        <p className='text-muted'>
                            Join VeriHealth today and experience the convenience and peace of mind that comes with verified credentials. Trust us to help you showcase your expertise and stand out in the competitive healthcare industry.
                        </p>
                    </Col>
                    <Row className='py-lg-4 py-3'>
                        {reasons.map((reason) => (
                            <Col xs={12} lg={4} key={reason.id}>
                                <Card data-aos="fade-up" className='px-3 py-2 border-0 my-3  text-center bg-black'
                                    style={{ borderRadius: "20px", transition: "transform 0.3s ease, box-shadow 0.3s ease " }}>
                                    <div>
                                        <div className='flipicon'>
                                        <span >{reason.icon}</span> 
                                        </div>
                                      
                                        <div className='text-center py-4 text-white'>
                                            <h4 className='fw-bold' style={{ fontSize: "18px" }}> {reason.title}</h4>
                                            <p style={{ minHeight: '70px', fontSize: "16px" }} >{reason.description}</p>
                                        </div>
                                       

                                    </div>
                                </Card>

                            </Col>
                        ))}
                    </Row>


                </Container>
            </div>
        </>
    );
};

export default WhyChooseUs;
