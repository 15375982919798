import PageHeading from 'components/Shared/PageHeading';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { GiProcessor } from "react-icons/gi";
import { RiCustomerService2Line } from "react-icons/ri";
import { RiServiceLine } from "react-icons/ri";

const features = [
    {
        id: 1,
        title: 'Streamlined Verification Process',
        description: 'Easily verify your credentials in just a few simple steps.',
        bgColor: 'bg-secondary',
        icon: <GiProcessor size={36} color='#04ECF0' />,
    },

    {

        id: 2,
        title: 'Comprehensive Services',
        description: 'Choose from a range of verification services tailored to your needs.',
        bgColor: 'bg-secondary',
        icon: <RiCustomerService2Line size={36} color='#04ECF0' />,
    },
    {
        id: 3,
        title: 'Trusted Partner:',
        description: 'Join a network of healthcare professionals and organizations who rely on us for credential verification.',
        bgColor: 'bg-secondary',
        icon: <RiServiceLine size={36} color='#04ECF0' />,
    }
];


const KeyFeatures = () => {
    return (
        <div className='keyfeaturesbg mt-5' id='key-featured'>

            <Container className='py-lg-5 py-3' data-aos="fade-up">
                <PageHeading heading={"Key Features"} sub />

                <Row className='py-lg-4 py-3'>
                    {features.map((feature) => (
                        <Col xs={12} lg={4} key={feature.id}>

                            <Card
                                data-aos="fade-up"
                                className=' px-4 py-3 my-2'
                                style={{ borderRadius: "20px", transition: "transform 0.3s ease, box-shadow 0.3s ease" }}
                            >

                                <div className='d-flex align-items-center justify-content-center mb-3' style={{ height: "60px", width: "60px", borderRadius: "60px", background: "#000000" }}>
                                    <span className='flipxicon'>{feature.icon}</span>
                                </div>
                                <div className='text-start'>
                                    <h4 className='fw-bold' style={{ fontSize: "18px" }}> {feature.title}</h4>
                                </div>
                                <p style={{ minHeight: '70px', fontSize: "16px" }} >{feature.description}</p>

                            </Card>

                        </Col>
                    ))}
                </Row>
            </Container>
        </div>

    );
};

export default KeyFeatures;
