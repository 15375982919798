import React from 'react';

const PageHeading = ({ heading, text, sub }) => {
    return (
        <div className={`text-center  ${sub ? "bg-transparent py-2" : "bg-black py-5"}`}>
            <h3 className={`fw-bold mb-3 ${sub ? "text-black" : "text-white"}`}>{heading}</h3>
            <div
                className="heading-bar mx-auto"
                style={{
                    width: '130px',
                    height: '8px',
                    borderRadius: '60px',
                    background: `${sub ? "black" : "white"}`,
                    position: 'relative',
                    overflow: 'hidden',

                }}
            >

            </div>
            <div className={'mt-3 heading-text mx-auto'} style={{ fontSize: "16px" }}>
                {text && <p className={`${sub ? "text-black" : "text-white"}`}>{text}</p>}
            </div>

        </div>
    );
};

export default PageHeading;
