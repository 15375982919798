import ArticlesAndBlogsCards from 'components/Shared/ArticlesAndBlogsCards';
import newsData from 'components/Shared/News';
import PageHeading from 'components/Shared/PageHeading';
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';

const News = () => {
    return (
        <>
         <PageHeading heading={"News"} />
            <Container className='py-5' data-aos="fade-up">
               
                <Row >
                    {newsData.map((blog) => (
                        <Col xs={12} md={6} lg={4} key={blog.id}>
                            <ArticlesAndBlogsCards
                                slug={blog.slug}
                                title={blog.title}
                                content={blog.content}
                                image={blog.image}
                                type="news"
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
};

export default News;
