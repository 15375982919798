import React from 'react'
import HeroSection from 'components/HomeComponents/HeroSection'
import KeyFeatures from 'components/HomeComponents/KeyFeatures'
import WhyChooseUs from 'components/HomeComponents/WhyChooseUs'
import Services from '../components/HomeComponents/Services'
import Banner from 'components/Shared/Banner'
import HowItWorks from '../components/HomeComponents/HowItWorks'
import VerifiedBanner from 'components/HomeComponents/VerifiedBanner'

const Home = () => {
    return (
        <>
            <HeroSection />
            <VerifiedBanner />
            <Services />
            <HowItWorks />
            <WhyChooseUs />
            <KeyFeatures />
            <Banner />
        </>
    )
}

export default Home